import { useServerDatetime } from 'services/clientSide/hooks/useServerDatetime'

import { DEFAULT_HIGH_PRIORITY_REQUEST_REVALIDATE_INTERVAL } from 'constants/requests'

export const useServerTime = (
  updateTick: 'disabled' | number = DEFAULT_HIGH_PRIORITY_REQUEST_REVALIDATE_INTERVAL
) => {
  const useServerDatetimeResponse = useServerDatetime({
    isPaused() {
      return updateTick === 'disabled'
    },
    refreshInterval:
      updateTick !== 'disabled'
        ? updateTick
        : DEFAULT_HIGH_PRIORITY_REQUEST_REVALIDATE_INTERVAL,
  })

  return {
    currentServerTime: useServerDatetimeResponse.response?.data.current,
    updateServerTime: useServerDatetimeResponse.mutate,
    isLoading: useServerDatetimeResponse.isLoading,
  }
}
