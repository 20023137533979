import { useRequest, Params } from 'hooks/useRequest'

import { getServiceDatetime } from 'services/clientSide/http/datetime'

import { DEFAULT_HIGH_PRIORITY_REQUEST_REVALIDATE_INTERVAL } from 'constants/requests'

export const useServerDatetime = (params: Params<typeof getServiceDatetime>) => {
  const response = useRequest({
    service: getServiceDatetime(),
    requireAuth: false,
    refreshInterval: DEFAULT_HIGH_PRIORITY_REQUEST_REVALIDATE_INTERVAL,
    ...params,
  })

  return response
}
