import Image from 'next/image'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import { useAuth } from '@clerk/nextjs'
import { Box, Button, Flex, Text } from '@nexpy/design-system'
import { NEXT_PUBLIC_COMPANY_CREATION_ACTIVE } from 'environment'
import { useClientSide } from 'hooks/useClientSide'
import { useServerTime } from 'hooks/useServerTime'
import { useTime } from 'hooks/useTime'
import { Schedule } from 'models/schedules'
import ms from 'ms'
import { CompaniesRoutes } from 'routes/companies'
import { MySchedulesRoutes } from 'routes/my-schedules'
import { useDebounce, useMediaQuery, useSessionStorage } from 'usehooks-ts'

import Container from 'components/generics/Container'
import FadeIn from 'components/generics/FadeIn'
import FeatureNotice from 'components/generics/FeatureNotice/FeatureNotice'
import Footer from 'components/generics/Footer'
import Publication from 'components/generics/Publication'
import CoWorkers from 'components/images/CoWorkers'

import { CompanyContext } from 'contexts/CompanyContext'
import { useCompanyRecommendations } from 'services/clientSide/hooks/useCompanyRecommendations'
import { useCurrentUserSchedules } from 'services/clientSide/hooks/useCurrentUserSchedules'

import { MOBILE_BREAKPOINT, TOPBAR_HEIGHT } from 'constants/layout'

const Home = () => {
  const [nextScheduleEventFired, setNextScheduleEventFired] = useSessionStorage<boolean>(
    '_internal-nextSheduleEventFired',
    false
  )
  const [isNeededNextScheduleEvent, setIsNeededNextScheduleEvent] =
    useState<boolean>(false)
  const [isShowingNextScheduleEvent, setIsShowingNextScheduleEvent] = useState<
    boolean | null
  >(false)

  const userCompanies = CompanyContext.useSelector(state => state.userCompanies)
  const isLoadingUserCompanies = CompanyContext.useSelector(state => state.isLoading)

  const debouncedIsShowingNextScheduleEvent = useDebounce(isShowingNextScheduleEvent, 300)

  const { isSignedIn, isLoaded } = useAuth()
  const { dayjs } = useTime()

  const isMobile = useMediaQuery(MOBILE_BREAKPOINT)

  const router = useRouter()

  const showCreateCompanyButton = useMemo(() => {
    return !userCompanies?.length && !isLoadingUserCompanies
  }, [isLoadingUserCompanies, userCompanies?.length])

  // TODO: reduzir em uma request mais leve
  const useCurrentUserSchedulesResponse = useCurrentUserSchedules({
    isPaused() {
      return isLoaded && !isSignedIn
    },
  })

  const useCompanyRecommendationsResponse = useCompanyRecommendations()

  const isLoadingRecommendations = useDebounce(
    useCompanyRecommendationsResponse.isLoading,
    ms('1s')
  )

  const { currentServerTime } = useServerTime(ms('1m'))

  const currentTime = useMemo(() => {
    if (!currentServerTime) {
      return null
    }

    return dayjs(currentServerTime)
  }, [currentServerTime, dayjs])

  const groupedSchedules = useMemo(() => {
    if (!currentTime) {
      return null
    }

    const nextSchedules: Schedule[] = []
    const pastSchedules: Schedule[] = []

    useCurrentUserSchedulesResponse.response?.data.forEach(schedule => {
      if (schedule.isActive && dayjs(schedule.startTime).isAfter(currentTime)) {
        nextSchedules.push(schedule)

        return
      }

      pastSchedules.push(schedule)
    })

    return {
      nextSchedules: nextSchedules.sort((a, b) =>
        dayjs(a.startTime).isAfter(dayjs(b.startTime)) ? 1 : -1
      ),
      pastSchedules,
    }
  }, [currentTime, dayjs, useCurrentUserSchedulesResponse.response?.data])

  const isClient = useClientSide()

  const nextSchedule = useMemo(
    () => groupedSchedules?.nextSchedules?.[0] || null,
    [groupedSchedules?.nextSchedules]
  )

  useEffect(() => {
    if (!isMobile || nextScheduleEventFired) {
      return
    }

    if (!nextSchedule) {
      setIsShowingNextScheduleEvent(null)

      return
    }

    setIsNeededNextScheduleEvent(true)
  }, [isMobile, nextSchedule, nextScheduleEventFired, setNextScheduleEventFired])

  useEffect(() => {
    const clear = setTimeout(() => {
      setIsShowingNextScheduleEvent(null)
    }, ms('3s'))

    if (nextScheduleEventFired) {
      return () => {
        clearTimeout(clear)
      }
    }

    if (isNeededNextScheduleEvent) {
      setTimeout(() => {
        setNextScheduleEventFired(true)

        setIsShowingNextScheduleEvent(true)
      }, ms('3s'))
    }

    return () => {
      clearTimeout(clear)
    }
  }, [isNeededNextScheduleEvent, nextScheduleEventFired, setNextScheduleEventFired])

  return (
    <>
      {isClient ? (
        <Box
          position='fixed'
          opacity={isShowingNextScheduleEvent ? 1 : 0}
          top={isShowingNextScheduleEvent ? `calc(${TOPBAR_HEIGHT} + 2.4rem)` : '-6rem'}
          left='2.4rem'
          right='calc(2.4rem + 8px)'
          zIndex='10'
          bg='lavenderIndigo'
          borderRadius='6px'
          pointerEvents='auto'
          role='button'
          onClick={() => {
            router.push(MySchedulesRoutes.ROOT)
          }}
          display={
            isMobile &&
            nextSchedule &&
            (typeof isShowingNextScheduleEvent === 'boolean' ||
              typeof debouncedIsShowingNextScheduleEvent === 'boolean')
              ? 'block'
              : 'none'
          }
          transition='all 0.2s ease'
        >
          <Flex justifyContent='center' alignItems='center'>
            <Text
              px='0.8rem'
              fontSize={{ _: '2rem !important', md: '2rem !important' }}
              mt='1.2rem'
              textAlign='center'
              color='white'
            >
              Próximo agendamento
            </Text>
          </Flex>

          <Flex
            justifyContent='space-between'
            minHeight='7rem'
            p='1.2rem'
            alignItems='center'
            gap='1.2rem'
            flexWrap='wrap'
            w='100%'
          >
            <Flex alignItems='center' gap='1.2rem' flexGrow='1'>
              {nextSchedule?.Company?.CompanyDetails?.LogoImage?.url ? (
                <Box
                  w='fit-content'
                  h='fit-content'
                  borderRadius='50%'
                  overflow='hidden'
                  bg='white'
                >
                  <Image
                    src={nextSchedule?.Company?.CompanyDetails?.LogoImage?.url}
                    alt={
                      nextSchedule?.Company?.CompanyDetails?.LogoImage?.alt ||
                      'Logo da empresa'
                    }
                    width={36}
                    height={36}
                    priority
                    style={{
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              ) : (
                <Flex
                  background='linear-gradient(136.97deg, #9855FF 15.48%, #5C50FF 88.07%)'
                  w='3.6rem'
                  h='3.6rem'
                  alignItems='center'
                  justifyContent='center'
                  borderRadius='50%'
                >
                  <Text color='white' fontWeight='600'>
                    {nextSchedule?.Company?.name?.trim()?.[0] || ':)'}
                  </Text>
                </Flex>
              )}

              <Box>
                <Text
                  color='white'
                  maxWidth={{ _: '40vw', md: '20rem', lg: '28rem' }}
                  whiteSpace='nowrap'
                  textOverflow='ellipsis'
                  overflow='hidden'
                  fontSize='1.1rem !important'
                >
                  {nextSchedule?.Company?.username
                    ? `@${nextSchedule.Company.username}`
                    : ''}
                </Text>
                <Text
                  color='white'
                  maxWidth={{ _: '40vw', md: '20rem', lg: '28rem' }}
                  whiteSpace='nowrap'
                  textOverflow='ellipsis'
                  overflow='hidden'
                  fontSize='1.4rem !important'
                  fontWeight='500'
                >
                  {nextSchedule?.Company?.name || '-'}
                </Text>
              </Box>
            </Flex>

            <Flex
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              gap='0.8rem'
              mr='1.2rem'
            >
              <Text color='white' fontWeight='500'>
                {nextSchedule ? dayjs(nextSchedule.startTime).format('DD/MM/YYYY') : ''}{' '}
                às {nextSchedule ? dayjs(nextSchedule.startTime).format('HH:mm') : ''}
              </Text>
              <Text
                color='white'
                maxWidth={{ _: '40vw', md: '20rem', lg: '28rem' }}
                whiteSpace='nowrap'
                textOverflow='ellipsis'
                overflow='hidden'
                variant='caption'
              >
                {nextSchedule?.CompanyService?.name || '-'}
              </Text>
            </Flex>
          </Flex>
        </Box>
      ) : null}

      <Flex
        flexDirection={{ _: 'column-reverse', md: 'row' }}
        justifyContent={{ md: 'space-between' }}
      >
        <Box w='100%' mt={{ _: '1.2rem', md: '1.2rem' }}>
          <Container gap='4.2rem' maxWidth='50rem'>
            <Publication
              header={{
                username: 'nexpy',
                verified: true,
                onClickUsername: () => {
                  router.push(
                    CompaniesRoutes.PROFILE({
                      username: 'nexpy',
                    })
                  )
                },
                image: {
                  src: '/images/icons/icon-192x192.png',
                  imageAlt: 'Nexpy Logo',
                },
              }}
              content={{
                createdAt: '2023-05-23T12:20:31.610Z',
                media: [
                  {
                    type: 'image',
                    src: '/images/posts/default-post-1_1.png',
                    imageAlt: 'Publicação',
                  },
                  {
                    type: 'image',
                    src: '/images/posts/default-post-1_2.png',
                    imageAlt: 'Publicação',
                  },
                  {
                    type: 'image',
                    src: '/images/posts/default-post-1_3.png',
                    imageAlt: 'Publicação',
                  },
                ],
              }}
            />{' '}
            <Publication
              header={{
                username: 'nexpy',
                verified: true,
                onClickUsername: () => {
                  router.push(
                    CompaniesRoutes.PROFILE({
                      username: 'nexpy',
                    })
                  )
                },
                image: {
                  src: '/images/icons/icon-192x192.png',
                  imageAlt: 'Nexpy Logo',
                },
              }}
              content={{
                createdAt: '2023-05-24T12:20:31.610Z',
                media: [
                  {
                    type: 'image',
                    src: '/images/posts/default-post-2_1.png',
                    imageAlt: 'Publicação',
                  },
                  {
                    type: 'image',
                    src: '/images/posts/default-post-2_2.png',
                    imageAlt: 'Publicação',
                  },
                  {
                    type: 'image',
                    src: '/images/posts/default-post-2_3.png',
                    imageAlt: 'Publicação',
                  },
                ],
              }}
            />
            <FeatureNotice noDivider description='Não há mais publicações no momento.' />
          </Container>
        </Box>
        <Box
          display={{ _: 'none', md: 'block' }}
          w={{ md: '42rem', lg: '46rem', xl: '50rem' }}
          minWidth={{ md: '42rem', lg: '46rem', xl: '50rem' }}
        />

        <Flex
          p={{ md: '4.2rem 1.2rem' }}
          position={{ md: 'fixed' }}
          top={{ md: TOPBAR_HEIGHT }}
          bottom={{ md: '0' }}
          right={{ md: '3rem' }}
          w={{ md: '42rem', lg: '46rem', xl: '50rem' }}
          flexDirection='column'
          gap={{ md: '2.4rem' }}
          overflowY='auto'
          pointerEvents={{ md: 'none' }}
          minHeight={{ _: '8.6rem', md: 'none' }}
        >
          {nextSchedule && isClient ? (
            <FadeIn>
              <Box display={{ _: 'none', md: 'block' }}>
                <Text fontSize='2.1rem !important' mb='1.2rem'>
                  Próximo agendamento
                </Text>

                <Flex
                  justifyContent='space-between'
                  bg='lavenderIndigo'
                  minHeight='7rem'
                  p='1.2rem'
                  borderRadius='6px'
                  alignItems='center'
                  gap='1.2rem'
                  flexWrap='wrap'
                  role='button'
                  pointerEvents='auto'
                  onClick={() => {
                    router.push(MySchedulesRoutes.ROOT)
                  }}
                >
                  <Flex alignItems='center' gap='1.2rem' flexGrow='1'>
                    {nextSchedule?.Company?.CompanyDetails?.LogoImage?.url ? (
                      <Box
                        w='fit-content'
                        h='fit-content'
                        borderRadius='50%'
                        overflow='hidden'
                        bg='white'
                      >
                        <Image
                          src={nextSchedule?.Company?.CompanyDetails?.LogoImage?.url}
                          alt={
                            nextSchedule?.Company?.CompanyDetails?.LogoImage?.alt ||
                            'Logo da empresa'
                          }
                          width={36}
                          height={36}
                          priority
                          style={{
                            objectFit: 'cover',
                          }}
                        />
                      </Box>
                    ) : (
                      <Flex
                        background='linear-gradient(136.97deg, #9855FF 15.48%, #5C50FF 88.07%)'
                        w='3.6rem'
                        h='3.6rem'
                        alignItems='center'
                        justifyContent='center'
                        borderRadius='50%'
                      >
                        <Text color='white' fontWeight='600'>
                          {nextSchedule?.Company?.name?.trim()?.[0] || ':)'}
                        </Text>
                      </Flex>
                    )}

                    <Box>
                      <Text
                        color='white'
                        maxWidth={{ _: '40vw', md: '20rem', lg: '28rem' }}
                        whiteSpace='nowrap'
                        textOverflow='ellipsis'
                        overflow='hidden'
                        fontSize='1.1rem !important'
                      >
                        {nextSchedule?.Company?.username
                          ? `@${nextSchedule.Company.username}`
                          : ''}
                      </Text>
                      <Text
                        color='white'
                        maxWidth={{ _: '40vw', md: '20rem', lg: '28rem' }}
                        whiteSpace='nowrap'
                        textOverflow='ellipsis'
                        overflow='hidden'
                        fontSize='1.4rem !important'
                        fontWeight='500'
                      >
                        {nextSchedule?.Company?.name || '-'}
                      </Text>
                    </Box>
                  </Flex>

                  <Flex
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    gap='0.8rem'
                    mr='1.2rem'
                  >
                    <Text color='white' fontWeight='500'>
                      {dayjs(nextSchedule.startTime).format('DD/MM/YYYY')} às{' '}
                      {dayjs(nextSchedule.startTime).format('HH:mm')}
                    </Text>
                    <Text
                      color='white'
                      maxWidth={{ _: '40vw', md: '20rem', lg: '28rem' }}
                      whiteSpace='nowrap'
                      textOverflow='ellipsis'
                      overflow='hidden'
                      variant='caption'
                    >
                      {nextSchedule?.CompanyService?.name || '-'}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </FadeIn>
          ) : null}
          <FadeIn>
            <Flex
              pointerEvents={{ _: 'auto', md: 'none' }}
              overflowX={{ _: 'auto', md: 'none' }}
              flexDirection={{ md: 'column' }}
              gap='1.2rem'
              pb={{ _: '1.2rem', md: '0' }}
            >
              <Text fontSize='2.1rem !important' display={{ _: 'none', md: 'block' }}>
                Para você
              </Text>
              {isLoadingRecommendations || !isLoaded ? (
                <>
                  <Flex
                    gap='1.4rem'
                    w='100%'
                    alignItems='center'
                    display={{ _: '', md: 'none' }}
                  >
                    <Skeleton height='57px' width='57px' circle />
                    <Skeleton height='57px' width='57px' circle />
                    <Skeleton height='57px' width='57px' circle />
                  </Flex>
                  <Flex
                    display={{ _: 'none', md: 'flex' }}
                    gap='1.2rem'
                    w='100%'
                    flexDirection='column'
                  >
                    <Skeleton height='6.6rem' />
                    <Skeleton height='6.6rem' />
                    <Skeleton height='6.6rem' />
                  </Flex>
                </>
              ) : null}

              {!isLoadingRecommendations &&
              useCompanyRecommendationsResponse.response?.data?.length &&
              isLoaded
                ? useCompanyRecommendationsResponse.response.data.map(
                    (company, index) => {
                      const category =
                        company?.CompanyCategories?.find(
                          cat => cat.id === company.primaryCompanyCategoryId
                        ) || null

                      return (
                        <FadeIn
                          key={company.id}
                          transition={{ duration: 0.16, delay: index / 12 }}
                        >
                          <Flex
                            gap='1.2rem'
                            w={{ md: '100%' }}
                            flexDirection='column'
                            display={{ md: 'none' }}
                          >
                            <Button
                              variant='ghost'
                              onClick={() => {
                                if (company.username) {
                                  router.push(
                                    CompaniesRoutes.PROFILE({
                                      username: company.username,
                                    })
                                  )
                                }
                              }}
                            >
                              {company?.CompanyDetails?.LogoImage?.url ? (
                                <Box
                                  w='fit-content'
                                  h='fit-content'
                                  borderRadius='50%'
                                  overflow='hidden'
                                  bg='white'
                                  borderColor='lavenderIndigo'
                                  borderStyle='solid'
                                  borderWidth='2px'
                                >
                                  <Image
                                    src={company?.CompanyDetails?.LogoImage?.url}
                                    alt={
                                      company?.CompanyDetails?.LogoImage?.alt ||
                                      'Logo da empresa'
                                    }
                                    width={56}
                                    height={56}
                                    priority
                                    style={{
                                      objectFit: 'cover',
                                    }}
                                  />
                                </Box>
                              ) : (
                                <Box
                                  borderColor='lavenderIndigo'
                                  borderStyle='solid'
                                  borderWidth='2px'
                                  borderRadius='50%'
                                  overflow='hidden'
                                >
                                  <Flex
                                    background='linear-gradient(136.97deg, #9855FF 15.48%, #5C50FF 88.07%)'
                                    w='56px'
                                    h='56px'
                                    alignItems='center'
                                    justifyContent='center'
                                    borderRadius='50%'
                                  >
                                    <Text
                                      color='white'
                                      fontWeight='600'
                                      fontSize='3.4rem !important'
                                    >
                                      {company?.name?.trim()?.[0] || ':)'}
                                    </Text>
                                  </Flex>
                                </Box>
                              )}
                            </Button>
                          </Flex>

                          <Flex
                            gap='1.2rem'
                            w='100%'
                            flexDirection='column'
                            display={{ _: 'none', md: 'flex' }}
                          >
                            <Flex
                              justifyContent='space-between'
                              bg='lavenderIndigo'
                              minHeight='7rem'
                              p='1.2rem'
                              borderRadius='6px'
                              alignItems='center'
                              gap='1.2rem'
                              flexWrap='wrap'
                              role='button'
                              pointerEvents='auto'
                              onClick={() => {
                                if (company.username) {
                                  router.push(
                                    CompaniesRoutes.PROFILE({
                                      username: company.username,
                                    })
                                  )
                                }
                              }}
                            >
                              <Flex alignItems='center' gap='1.2rem' flexGrow='1'>
                                {company?.CompanyDetails?.LogoImage?.url ? (
                                  <Box
                                    w='fit-content'
                                    h='fit-content'
                                    borderRadius='50%'
                                    overflow='hidden'
                                    bg='white'
                                  >
                                    <Image
                                      src={company?.CompanyDetails?.LogoImage?.url}
                                      alt={
                                        company?.CompanyDetails?.LogoImage?.alt ||
                                        'Logo da empresa'
                                      }
                                      width={36}
                                      height={36}
                                      priority
                                      style={{
                                        objectFit: 'cover',
                                      }}
                                    />
                                  </Box>
                                ) : (
                                  <Flex
                                    background='linear-gradient(136.97deg, #9855FF 15.48%, #5C50FF 88.07%)'
                                    w='3.6rem'
                                    h='3.6rem'
                                    alignItems='center'
                                    justifyContent='center'
                                    borderRadius='50%'
                                  >
                                    <Text color='white' fontWeight='600'>
                                      {company?.name?.trim()?.[0] || ':)'}
                                    </Text>
                                  </Flex>
                                )}

                                <Box>
                                  <Text
                                    color='white'
                                    maxWidth={{ _: '40vw', md: '20rem', lg: '28rem' }}
                                    whiteSpace='nowrap'
                                    textOverflow='ellipsis'
                                    overflow='hidden'
                                    fontSize='1.1rem !important'
                                  >
                                    {company?.username ? `@${company.username}` : ''}
                                  </Text>
                                  <Text
                                    color='white'
                                    maxWidth={{ _: '40vw', md: '20rem', lg: '28rem' }}
                                    whiteSpace='nowrap'
                                    textOverflow='ellipsis'
                                    overflow='hidden'
                                    fontSize='1.4rem !important'
                                    fontWeight='500'
                                  >
                                    {company?.name || '-'}
                                  </Text>
                                </Box>
                              </Flex>

                              <Flex
                                mr='1.2rem'
                                alignItems='center'
                                justifyContent='flex-start'
                                gap='0.4rem'
                                h='1.8rem'
                                overflow='hidden'
                                style={{ filter: 'invert(100%)' }}
                              >
                                {category?.IconImage?.url ? (
                                  <Flex justifyContent='center' alignItems='center'>
                                    <Image
                                      src={category?.IconImage?.url}
                                      alt={category?.IconImage?.alt || 'Ícone'}
                                      width={14}
                                      height={14}
                                      priority
                                      style={{
                                        objectFit: 'cover',
                                      }}
                                    />
                                  </Flex>
                                ) : null}

                                <Text
                                  variant='caption'
                                  fontSize='1.4rem !important'
                                  textShadow='1px 0px 4px rgba(255,255,255,0.30)'
                                  color='black !important'
                                  fontWeight='500'
                                >
                                  {category?.name}
                                </Text>
                              </Flex>
                            </Flex>
                          </Flex>
                        </FadeIn>
                      )
                    }
                  )
                : null}

              {!isLoadingRecommendations &&
              !useCompanyRecommendationsResponse.response?.data?.length &&
              isLoaded ? (
                <Flex w='100%' alignItems='center'>
                  <Text color='silverChalice' variant='caption'>
                    Não há sugestões no momento.
                  </Text>
                </Flex>
              ) : null}
            </Flex>

            {NEXT_PUBLIC_COMPANY_CREATION_ACTIVE &&
            showCreateCompanyButton &&
            isClient ? (
              <Flex
                role='button'
                mt='2.4rem'
                bg='lavenderIndigo'
                borderRadius='6px'
                w='100%'
                pointerEvents='auto'
                justifyContent='space-between'
                alignItems='center'
                onClick={() => {
                  router.push(CompaniesRoutes.CREATE)
                }}
                display={{ _: 'none', md: 'flex' }}
                mb={{ _: '2.4rem', md: '0' }}
              >
                <Text
                  color='magnolia'
                  fontSize='2rem !important'
                  maxWidth='29rem'
                  textAlign='left'
                  p='1.2rem'
                  fontWeight='500 !important'
                >
                  Traga a sua empresa para a Nexpy
                </Text>

                <CoWorkers w='100%' maxWidth='10rem' />
              </Flex>
            ) : null}

            <Footer display={{ _: 'none', md: 'flex' }} />
          </FadeIn>
        </Flex>
      </Flex>
      {NEXT_PUBLIC_COMPANY_CREATION_ACTIVE && showCreateCompanyButton && isClient ? (
        <Flex
          role='button'
          mt='4.2rem'
          bg='lavenderIndigo'
          borderRadius='6px'
          w='100%'
          pointerEvents='auto'
          justifyContent='space-between'
          alignItems='center'
          onClick={() => {
            router.push(CompaniesRoutes.CREATE)
          }}
          display={{ md: 'none' }}
        >
          <Text
            color='magnolia'
            fontSize='2rem !important'
            maxWidth='29rem'
            textAlign='left'
            p='1.2rem'
            fontWeight='500 !important'
          >
            Traga a sua empresa para a Nexpy
          </Text>

          <CoWorkers w='100%' maxWidth='10rem' />
        </Flex>
      ) : null}
    </>
  )
}

export default Home
