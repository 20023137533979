import dynamic from 'next/dynamic'

import { Divider, Flex, FlexProps, Text, TextProps } from '@nexpy/design-system'

import { customTheme } from 'theme/theme'

const MdInfoOutline = dynamic(() =>
  import('react-icons/md').then(mod => mod.MdInfoOutline)
)

type FeatureNoticeProps = {
  noDivider?: boolean
  description: string
  descriptionProps?: TextProps
} & FlexProps

const FeatureNotice = ({
  noDivider,
  description,
  descriptionProps,
  ...props
}: FeatureNoticeProps) => (
  <>
    <Divider mt='4rem' display={noDivider ? 'none' : undefined} />

    <Flex
      mt='2.4rem'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      {...props}
    >
      <MdInfoOutline size='2rem' color={customTheme.colors.oldSilver} />
      <Text
        mt='0.6rem'
        variant='caption'
        maxWidth='40rem'
        textAlign='center'
        color='oldSilver'
        {...descriptionProps}
      >
        {description}
      </Text>
    </Flex>
  </>
)

FeatureNotice.defaultProps = {
  noDivider: undefined,
  descriptionProps: undefined,
}

export default FeatureNotice
