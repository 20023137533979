import { useEffect } from 'react'

import { useAuth } from '@clerk/nextjs'
import { useRequest, Params } from 'hooks/useRequest'
import ms from 'ms'
import { useMediaQuery } from 'usehooks-ts'

import { InternalUserContext } from 'contexts/InternalUserContext'
import { getServiceCompanyRecommendations } from 'services/clientSide/http/recommendations'

import { MOBILE_BREAKPOINT } from 'constants/layout'

export const useCompanyRecommendations = (
  params: Params<typeof getServiceCompanyRecommendations>
) => {
  const currentUser = InternalUserContext.useSelector(state => state.currentUser)
  const isLoadingUser = InternalUserContext.useSelector(state => state.isLoading)

  const { isLoaded } = useAuth()

  const isMobile = useMediaQuery(MOBILE_BREAKPOINT)

  const response = useRequest({
    service: getServiceCompanyRecommendations({
      forUserId: currentUser?.id || null,
      isMobile: isMobile || false,
    }),
    requireAuth: false,
    refreshInterval: () => ms('10m'),
    ...params,
    isPaused: () => isLoadingUser || !isLoaded,
  })

  useEffect(() => {
    if (isLoadingUser || !isLoaded) {
      return
    }

    response.mutate()
  }, [isLoaded, isLoadingUser, response])

  return {
    ...response,
    isLoading: response.isLoading || isLoadingUser || !isLoaded,
  }
}
