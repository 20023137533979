import { useRequest, Params } from 'hooks/useRequest'

import { getServiceCurrentUserSchedules } from 'services/clientSide/http/schedules'

export const useCurrentUserSchedules = (
  params: Params<typeof getServiceCurrentUserSchedules>
) => {
  const response = useRequest({
    service: getServiceCurrentUserSchedules(),
    requireAuth: true,
    ...params,
  })

  return response
}
