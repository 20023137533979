import { InternalUser } from 'models/internal-user'
import { Schedule } from 'models/schedules'

import { api } from 'providers/clientSide/nexpy'

import { createAuthHttpService, getApiAuthPath, withAuthHeader } from 'utils/http'

export const getServicePrivateSchedules = ({
  companyId,
  startTime,
  endTime,
}: {
  companyId: string
  startTime: string
  endTime: string
}) =>
  createAuthHttpService<Schedule[]>({
    fetcher: api.get,
    path: `/schedules/private/company/${companyId}`,
    args: {
      params: {
        startTime,
        endTime,
      },
    },
  })

export const getServicePublicSchedules = ({
  companyId,
  startTime,
  endTime,
  employeeId,
}: {
  companyId: string
  startTime: string
  endTime: string
  employeeId?: string | undefined
}) =>
  createAuthHttpService<Pick<Schedule, 'id' | 'companyId' | 'startTime' | 'endTime'>[]>({
    fetcher: api.get,
    path: `/schedules/public/company/${companyId}`,
    args: {
      params: {
        startTime,
        endTime,
        employeeId,
      },
    },
  })

export const getServiceSearchClients = ({
  companyId,
  q,
}: {
  companyId: string
  q: string
}) =>
  createAuthHttpService<InternalUser[]>({
    fetcher: api.get,
    path: `/schedules/search-users/company/${companyId}`,
    args: {
      params: {
        q,
      },
    },
  })

export const service_DeleteSchedule = (scheduleId: string, token: string | null) =>
  api.delete(getApiAuthPath(`/schedules/schedule/${scheduleId}`), withAuthHeader(token))

export const service_CreateSchedule = (
  companyId: string,
  data: {
    startTime: string
    clientName: string | null
    clientLastName: string | null
    customClientUserId: string | null
    companyServiceId: string
    employeeId: string
    customDurationMinutes: number | null
    createdByAdmin: boolean | null
  },
  token: string | null
) =>
  api.post<Schedule>(
    getApiAuthPath(`/schedules/company/${companyId}`),
    data,
    withAuthHeader(token)
  )

export const service_CreateNonDefaultSchedule = (
  companyId: string,
  data: {
    startTime: string
    notes: string
    customDurationMinutes: number
    employeeId: string
    ScheduleType: 'HOUR_BLOCKED'
  },
  token: string | null
) =>
  api.post<Schedule>(
    getApiAuthPath(`/schedules/non-default/company/${companyId}`),
    data,
    withAuthHeader(token)
  )

export const service_SetScheduleManualOffsets = (
  scheduleId: string,
  data: {
    startTime: string
    customDurationMinutes: number
  },
  token: string | null
) =>
  api.post(
    getApiAuthPath(`/schedules/set-manual-offsets/schedule/${scheduleId}`),
    data,
    withAuthHeader(token)
  )

export const getServiceCurrentUserSchedules = () =>
  createAuthHttpService<Schedule[]>({
    fetcher: api.get,
    path: `/schedules/current-user`,
  })

export const getServiceScheduleHistory = ({
  userId,
  companyId,
}: {
  userId: string
  companyId: string
}) =>
  createAuthHttpService<{
    Schedules: Schedule[]
    Client: InternalUser | null
  }>({
    fetcher: api.get,
    path: `/schedules/schedule-history/user/${userId}/company/${companyId}`,
  })

export const service_SetScheduleCustomManualInfo = (
  scheduleId: string,
  data: {
    customFinalPrice?: number | null
    notes?: string | null
  },
  token: string | null
) =>
  api.post<Schedule>(
    getApiAuthPath(`/schedules/set-custom-manual-info/schedule/${scheduleId}`),
    {
      ...(typeof data.notes === 'string' || data.notes === null
        ? {
            notes: data.notes,
          }
        : {}),

      ...(typeof data.customFinalPrice === 'number' || data.customFinalPrice === null
        ? {
            customFinalPrice: data.customFinalPrice,
          }
        : {}),
    },
    withAuthHeader(token)
  )

export const service_SetScheduleAbsentInfo = (
  scheduleId: string,
  data: {
    absent?: boolean
  },
  token: string | null
) =>
  api.post<Schedule>(
    getApiAuthPath(`/schedules/set-absent-info/schedule/${scheduleId}`),
    data,
    withAuthHeader(token)
  )
