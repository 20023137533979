import dynamic from 'next/dynamic'
import Image from 'next/image'
import { useMemo } from 'react'
import { Carousel } from 'react-responsive-carousel'

import { Box, Button, Flex, Text } from '@nexpy/design-system'
import { useClientSide } from 'hooks/useClientSide'
import { useServerTime } from 'hooks/useServerTime'
import { useTime } from 'hooks/useTime'
import ms from 'ms'

import FadeIn from 'components/generics/FadeIn'

import { DESKTOP_MIN_BREAKPOINT, MOBILE_BREAKPOINT } from 'constants/layout'

import { customTheme } from 'theme/theme'

const MdKeyboardArrowLeft = dynamic(() =>
  import('react-icons/md').then(mod => mod.MdKeyboardArrowLeft)
)

const MdKeyboardArrowRight = dynamic(() =>
  import('react-icons/md').then(mod => mod.MdKeyboardArrowRight)
)

const MdVerified = dynamic(() => import('react-icons/md').then(mod => mod.MdVerified))

type PublicationProps = {
  header: {
    username: string
    verified: boolean
    onClickUsername: () => void
    image?: {
      src: string
      imageAlt: string
    }
  }
  content: {
    createdAt: string
    media: {
      type: 'image'
      src: string
      imageAlt: string
    }[]
  }
}

const Publication = ({ header, content }: PublicationProps) => {
  const { dayjs } = useTime()

  const { currentServerTime } = useServerTime(ms('1m'))

  const isClient = useClientSide()

  const needSlide = useMemo(() => content.media.length > 1, [content.media.length])

  return (
    <FadeIn>
      <Box w='100%'>
        <Flex justifyContent='space-between' gap='1.2rem'>
          <Flex gap='1rem' alignItems='center'>
            {header.image?.src ? (
              <Box
                w='fit-content'
                h='fit-content'
                borderRadius='50%'
                overflow='hidden'
                bg='white'
                borderColor='lavenderIndigo'
                borderStyle='solid'
                borderWidth='1px'
                role='button'
                onClick={header.onClickUsername}
              >
                <Image
                  src={header.image?.src}
                  alt={header.image?.imageAlt || 'Logo da empresa'}
                  width={40}
                  height={40}
                  style={{
                    objectFit: 'cover',
                  }}
                  priority
                />
              </Box>
            ) : (
              <Flex
                background='linear-gradient(136.97deg, #9855FF 15.48%, #5C50FF 88.07%)'
                w='3.6rem'
                h='3.6rem'
                alignItems='center'
                justifyContent='center'
                borderRadius='50%'
              >
                <Text color='white' fontWeight='600'>
                  {header?.username?.trim()?.[0] || ':)'}
                </Text>
              </Flex>
            )}

            <Button variant='ghost' textAlign='left' onClick={header.onClickUsername}>
              <Box>
                <Flex alignItems='center' gap='0.4rem'>
                  <Text variant='caption' mb='0.4rem'>
                    {header.username ? `@${header.username}` : '-'}
                  </Text>

                  {header.verified ? (
                    <Box title='Perfil verificado'>
                      <MdVerified
                        color={customTheme.colors.lavenderIndigo}
                        size='1.4rem'
                      />
                    </Box>
                  ) : null}
                </Flex>
                <Text fontSize='1rem !important' color='silverChalice' h='1.1rem'>
                  {isClient ? dayjs(content.createdAt).from(currentServerTime) : ' '}
                </Text>
              </Box>
            </Button>
          </Flex>
        </Flex>

        <Box
          mt='1.4rem'
          borderColor='gainsboro'
          borderWidth='1px'
          borderStyle='solid'
          borderRadius='4px'
        >
          <Carousel
            showStatus={false}
            showThumbs={false}
            showIndicators={needSlide}
            emulateTouch
            preventMovementUntilSwipeScrollTolerance
            swipeScrollTolerance={10}
            renderArrowPrev={(clickHandler, hasPrev) => (
              <Button
                onClick={clickHandler}
                disabled={!hasPrev}
                variant='ghost'
                position='absolute'
                top='calc(50% - 2.4rem)'
                left='0'
                zIndex='2'
                aria-label='Anterior'
                p='1.2rem 1.2rem'
                opacity={hasPrev ? '0.7' : '0'}
                display={needSlide ? 'flex' : 'none'}
                cursor={hasPrev ? 'pointer' : 'default'}
                transition='all 0.12s ease'
              >
                <Box borderRadius='50%' bg='gainsboro'>
                  <MdKeyboardArrowLeft color={customTheme.colors.black} size='2.4rem' />
                </Box>
              </Button>
            )}
            renderArrowNext={(clickHandler, hasNext) => (
              <Button
                onClick={clickHandler}
                disabled={!hasNext}
                variant='ghost'
                position='absolute'
                top='calc(50% - 2.4rem)'
                right='0'
                zIndex='2'
                aria-label='Próximo'
                p='1.2rem 1.2rem'
                opacity={hasNext ? '0.7' : '0'}
                display={needSlide ? 'flex' : 'none'}
                cursor={hasNext ? 'pointer' : 'default'}
                transition='all 0.12s ease'
              >
                <Box borderRadius='50%' bg='gainsboro'>
                  <MdKeyboardArrowRight color={customTheme.colors.black} size='2.4rem' />
                </Box>
              </Button>
            )}
          >
            {content.media.map(media => (
              <Box className='square' key={media.src}>
                <Image
                  src={media.src}
                  alt={media.imageAlt}
                  style={{ objectFit: 'cover', aspectRatio: 1 / 1, width: '100%' }}
                  fill
                  priority
                  sizes={`${MOBILE_BREAKPOINT} 100vw, ${DESKTOP_MIN_BREAKPOINT} 33vw`}
                />
              </Box>
            ))}
          </Carousel>
        </Box>

        <style global jsx>{`
          * {
            user-select: none;
          }

          .square {
            width: 100%;
            position: relative;
          }

          .square::before {
            content: '';
            display: block;
            padding-bottom: 100%;
          }

          .carousel .slider.animated {
            transition: all 0.2s ease;
          }

          .dot {
            background-color: #dbdbdb !important;
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1) !important;
          }
        `}</style>
      </Box>
    </FadeIn>
  )
}

export default Publication
