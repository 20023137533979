import { ComponentProps, ReactNode } from 'react'

import { motion } from 'framer-motion'

type FadeInProps = {
  children: ReactNode
} & ComponentProps<typeof motion.div>

const FadeIn = ({ children, ...props }: FadeInProps) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.16 }}
    {...props}
  >
    {children}
  </motion.div>
)

export default FadeIn
