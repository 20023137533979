import { Box, BoxProps } from '@nexpy/design-system'

import { customTheme } from 'theme/theme'

type CoWorkersProps = {
  color?: string
} & BoxProps

const CoWorkers = ({ color, ...props }: CoWorkersProps) => (
  <Box {...props}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      viewBox='0 0 715.29587 604.16998'
    >
      <path
        d='M948.01805,354.635q1.25976,4.92,2.36035,9.89H700.64793v-9.89Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#e6e6e6'
      />
      <path
        d='M940.05813,328.565a305.61151,305.61151,0,0,0-14.04-33.26q-2.46-4.995-5.11-9.89c-.29-.56-.6-1.12-.91-1.68v73.6h21.58v-24.39Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#e6e6e6'
      />
      <path
        d='M926.01805,295.305h-6.02V285.415h.91016Q923.56322,290.305,926.01805,295.305Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#ccc'
      />
      <polygon
        points='697.706 180.65 677.646 180.65 677.646 190.54 699.226 190.54 699.226 185.03 697.706 180.65'
        fill='#ccc'
      />
      <rect x='644.38463' y='124.017' width='21.57574' height='85.4041' fill='#e6e6e6' />
      <rect x='644.38463' y='137.50186' width='21.57574' height='9.8889' fill='#ccc' />
      <rect x='644.38463' y='180.65341' width='21.57574' height='9.8889' fill='#ccc' />
      <path
        d='M809.918,271.435h-77.3999a4.50681,4.50681,0,0,0-4.5,4.5v77.4a4.65641,4.65641,0,0,0,.18994,1.3,4.50994,4.50994,0,0,0,4.31006,3.2H809.918a4.50948,4.50948,0,0,0,4.31006-3.2,4.63848,4.63848,0,0,0,.18994-1.3v-77.4A4.50681,4.50681,0,0,0,809.918,271.435Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#e6e6e6'
      />
      <path
        d='M804.418,276.935h-66.3999a4.50681,4.50681,0,0,0-4.5,4.5v66.4a4.50681,4.50681,0,0,0,4.5,4.5H804.418a4.50681,4.50681,0,0,0,4.5-4.5v-66.4A4.50681,4.50681,0,0,0,804.418,276.935Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#fff'
      />
      <path
        d='M792.83836,288.515H749.59813a4.50681,4.50681,0,0,0-4.5,4.5v43.24a4.50676,4.50676,0,0,0,4.5,4.5h43.24023a4.52349,4.52349,0,0,0,4.5-4.5v-43.24A4.50681,4.50681,0,0,0,792.83836,288.515Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#ccc'
      />
      <path
        d='M790.17235,328.62827l-9.13281-15.819a2.00023,2.00023,0,0,0-3.46436,0l-6.66895,11.55108-8.74609-15.14728a1.99984,1.99984,0,0,0-3.46387.00007l-9.1333,15.81909-10.86474,18.819H759.6533l-2.07617,3.596h43.46094Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#fff'
      />
      <circle cx='544.59673' cy='150.08772' r='6.29292' fill='#fff' />
      <path
        d='M957.56834,424.515q-.10547-5.82-.46045-11.58c-.0996-1.84-.21972-3.67-.36962-5.5H914.51805a4.50681,4.50681,0,0,0-4.5,4.5v77.4a4.08953,4.08953,0,0,0,.12988,1.05,4.50432,4.50432,0,0,0,4.37012,3.45H953.438c.2002-1.14.39014-2.3.56006-3.45.10986-.68.22022-1.37.32031-2.05.23975-1.63.46973-3.26.67969-4.89q.45043-3.33.81006-6.69,1.31982-12.04494,1.7002-24.35v-.01q.13476-4.635.13964-9.31v-12C957.64793,428.885,957.6284,426.695,957.56834,424.515Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#e6e6e6'
      />
      <path
        d='M957.56834,424.515q-.10547-5.82-.46045-11.58H920.01805a4.50681,4.50681,0,0,0-4.5,4.5v66.4a4.50681,4.50681,0,0,0,4.5,4.5h34.30029c.23975-1.63.46973-3.26.67969-4.89q.45043-3.33.81006-6.69,1.31982-12.04494,1.7002-24.35v-.01q.13476-4.635.13964-9.31v-12C957.64793,428.885,957.6284,426.695,957.56834,424.515Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#fff'
      />
      <path
        d='M957.56834,424.515H931.59813a4.50681,4.50681,0,0,0-4.5,4.5v43.24a4.50676,4.50676,0,0,0,4.5,4.5h24.21q1.31982-12.04494,1.7002-24.35v-.01q.13476-4.635.13964-9.31v-12C957.64793,428.885,957.6284,426.695,957.56834,424.515Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#ccc'
      />
      <path
        d='M957.50829,452.405q-.375,12.3-1.7002,24.35-.35962,3.36-.81006,6.69H939.57811l2.08008-3.59h-20.96l6.3999-11.09,4.46-7.73,9.14014-15.82a1.99145,1.99145,0,0,1,3.46,0l8.75,15.15Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#fff'
      />
      <path
        d='M881.73827,228.785H700.64793v-9.89H872.75829Q877.34837,223.74,881.73827,228.785Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#e6e6e6'
      />
      <path
        d='M796.83836,160.195q11.06984,6.09,21.57959,13.05v48.34H796.83836Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#e6e6e6'
      />
      <rect x='554.48565' y='44.90582' width='21.57574' height='9.8889' fill='#ccc' />
      <path
        d='M851.67821,198.645v22.94H830.09813v-40.21q7.54541,5.50506,14.75,11.45Q848.29783,195.675,851.67821,198.645Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#e6e6e6'
      />
      <path
        d='M851.67821,198.645v4.06H830.09813v-9.88h14.75Q848.29783,195.675,851.67821,198.645Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#ccc'
      />
      <path
        d='M774.80809,149.175q8.56494,3.85491,16.83984,8.22l-4.43994,8.96-14.75,29.81-4.38965,8.86-6.87011,13.87-1.51026,3.05-6.1499-3.05-13.18018-6.53,8.37012-16.92h.01026l4.37988-8.86,14.76025-29.81,4.33985-8.76.0498-.1Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#e6e6e6'
      />
      <polygon
        points='549.246 9.57 544.856 18.44 525.526 8.86 529.866 0.1 529.916 0 532.456 1.26 549.246 9.57'
        fill='#ccc'
      />
      <rect
        x='755.6482'
        y='185.01745'
        width='9.8889'
        height='21.57574'
        transform='translate(5.29589 642.64123) rotate(-63.6604)'
        fill='#ccc'
      />
      <path
        d='M953.998,490.385c-.16992,1.15-.35986,2.31-.56006,3.45-.34961,2.16-.73,4.3-1.13965,6.44H700.64793v-9.89Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#e6e6e6'
      />
      <rect
        x='554.48565'
        y='259.76454'
        width='21.57574'
        height='85.40411'
        fill='#e6e6e6'
      />
      <rect x='554.48565' y='273.24943' width='21.57574' height='9.88889' fill='#ccc' />
      <rect x='554.48565' y='316.40098' width='21.57574' height='9.88889' fill='#ccc' />
      <rect
        x='587.74828'
        y='259.76454'
        width='21.57574'
        height='85.40411'
        fill='#e6e6e6'
      />
      <rect x='587.74828' y='273.24943' width='21.57574' height='9.88889' fill='#ccc' />
      <rect x='587.74828' y='316.40098' width='21.57574' height='9.88889' fill='#ccc' />
      <rect
        x='726.26691'
        y='439.59375'
        width='85.40411'
        height='21.57574'
        transform='translate(-218.19047 791.77108) rotate(-63.6604)'
        fill='#e6e6e6'
      />
      <rect
        x='774.79411'
        y='417.84108'
        width='9.88889'
        height='21.57574'
        transform='translate(-192.70495 789.32135) rotate(-63.6604)'
        fill='#ccc'
      />
      <rect
        x='755.64818'
        y='456.51258'
        width='9.88889'
        height='21.57574'
        transform='translate(-238.01263 793.67647) rotate(-63.6604)'
        fill='#ccc'
      />
      <path
        d='M535.15014,528.49686a19.79134,19.79134,0,0,1,2.57027,1.762L626.74915,502.553l5.02034-22.08811,34.32513,3.21966-5.08744,39.96635a15.0408,15.0408,0,0,1-12.56839,12.95649L543.66567,553.19537a19.73558,19.73558,0,1,1-8.51553-24.69851Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#ffb8b8'
      />
      <path
        d='M681.958,496.935l-.58984-10.08v-.01l-.16016-2.76-1.73974-29.68a23.47273,23.47273,0,0,0-21.33008-25.35,23.14957,23.14957,0,0,0-5.33985.14,23.54326,23.54326,0,0,0-20.15039,21.18l-3.60986,15.02-6.31006,26.27a8.41756,8.41756,0,0,0,4.34033,9.48,8.27547,8.27547,0,0,0,3.12989.92l10.52.94,10.12988.9,11.68994,1.04.84033.07,2.15967.2,7.23.64h.02a8.56288,8.56288,0,0,0,6.62012-2.36005,8.58059,8.58059,0,0,0,1.58984-2.12,8.24536,8.24536,0,0,0,.96-4.44Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#5c50ff'
      />
      <path
        d='M779.65374,374.70751c0,34.22533-31.64676,29.04864-70.685,29.04864s-70.685,5.17669-70.685-29.04864,15.45864-94.89222,70.685-94.89222C766.09771,279.81529,779.65374,340.48219,779.65374,374.70751Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#2f2e41'
      />
      <circle
        cx='708.73237'
        cy='356.13468'
        r='47.56429'
        transform='translate(-286.59441 457.54389) rotate(-45)'
        fill='#ffb8b8'
      />
      <path
        d='M775.688,423.695a29.37016,29.37016,0,0,0-2.90967-2.46,30.91983,30.91983,0,0,0-22.63037-5.99006l-87.6499,11.09a31.11934,31.11934,0,0,0-27.1499,33.78c.25,2.8.53027,5.75.83984,8.82.40039,3.85.8501,7.89,1.37012,12.07.85986,7.03,1.91016,14.45,3.16016,22,1.08984,6.5,2.33007,13.09,3.75,19.6q.61523,2.82,1.27978,5.62a.03607.03607,0,0,0,.01026.03c.0498.23005.0996.46.15966.68a.07353.07353,0,0,0,.01026.05q.93016,3.9,1.95019,7.71c.25977.94.50977,1.87.76953,2.79.7002,2.5,1.44043,4.95,2.2002,7.36,5.71,17.93,13.25976,33.2,23.23,41.32.12988.12.26025.22.3999.32995l.40039.31.57959-.18,2.46-.73,2.1001-.64,27.36035-8.23,6.80957-2.05,21.88037-6.59h.00977l20.01025-6.02,2.43994-.74,17.19-5.17.5498-6.42.74024-8.52.43994-5.12,3.48-40.38,2.02978-23.56,2.2002-25.58A31.07666,31.07666,0,0,0,775.688,423.695Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#2f2e41'
      />
      <path
        d='M824.69823,619.085c-.23-.67-.4502-1.34-.68018-2-10.27978-30-25.43994-52.69-47.75-65.05-.00976,0-.00976-.01-.02-.01l-.37988-.21-19.43994,6.73-2.39991.83-2.5.87-.03027.01-18.19971,6.3-.03027.01-14.27978,4.94H718.978l-13.21,4.57h-.00976l-3.07032,1.07-23.08984,7.99-2.68018.93-2.3999.83-.37012.13-.06982,1.14-1.6499,28.92-.10987,2-2.06005,36.09-68.41016,96.91h46.7998a308.16253,308.16253,0,0,0,46.84034-3.57l43.90966-47.32a2.9075,2.9075,0,0,1,4.94043,1.44l6.44971,32a309.22958,309.22958,0,0,0,88-48.42C835.708,661.365,831.208,638.715,824.69823,619.085Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#2f2e41'
      />
      <path
        d='M653.77631,351.46855v0H672.5889l8.29947-23.23891,1.65952,23.23891h8.99165l4.84144-13.556.96829,13.556h66.81187v0a50.35092,50.35092,0,0,0-50.35093-50.35092h-9.683A50.35092,50.35092,0,0,0,653.77631,351.46855Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#2f2e41'
      />
      <path
        d='M538.06146,603.47244a22.78775,22.78775,0,0,1-31.05941-16.00863l-80.46558-9.11312,26.973-32.30021,72.27622,13.606a22.91124,22.91124,0,0,1,12.27573,43.816Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#a0616a'
      />
      <path
        d='M494.67821,543.685l-34.01026-6.08-2.46972-.44a14.21295,14.21295,0,0,1-11.10987-18.09l16.69971-55.49.5-1.65a32.46828,32.46828,0,0,0-15.23-37.51,32.27694,32.27694,0,0,0-47.02,20.54l-21.2998,86.93-.79,3.22a43.43889,43.43889,0,0,0,38.16992,53.6c.06982.01.12988.01.20019.02l12.75,1.13,16.53955,1.47,20.8003,1.85,19.41015,1.73a10.17352,10.17352,0,0,0,10.94-8.38c.02978-.14.0498-.28.06982-.42l4.12012-30.76.04-.28A10.17007,10.17007,0,0,0,494.67821,543.685Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#ccc'
      />
      <path
        d='M442.27994,310.61657c-13.57683,26.69293-24.277,54.22413-54.22412,54.22413a54.22413,54.22413,0,0,1-54.22413-54.22413c0-29.94715,24.27906-53.84919,54.22413-54.22412C418.4907,256.01137,467.55,260.933,442.27994,310.61657Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#2f2e41'
      />
      <path
        d='M476.33836,659.815l-1.23047-12.28-.91992.05L312.498,655.375l.41992,12.43.71045,21.29a308.81037,308.81037,0,0,0,82.38965,44.85l1.05029-8.14a2.90385,2.90385,0,0,1,5.67969-.36l3.18994,11.89a305.31881,305.31881,0,0,0,79.59033,14.41Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#2f2e41'
      />
      <circle
        cx='390.72433'
        cy='318.33238'
        r='47.5643'
        transform='translate(-353.00655 221.60621) rotate(-45)'
        fill='#a0616a'
      />
      <path
        d='M485.6284,648.365l-9.43017-29.28-.16016-.5-.48-1.5-2.68994-8.34c-.87012-2.71-1.7002-5.43-2.47022-8.16q-1.06494-3.69-1.98974-7.4-1.31984-5.16-2.3999-10.36a256.72524,256.72524,0,0,1-4.87012-35.33q-.33033-4.935-.47022-9.88995a255.64018,255.64018,0,0,1,5.43018-60.31,81.78628,81.78628,0,0,0-40.33984-88.57,80.416,80.416,0,0,0-32.64014-9.79c-.79-.06-1.58008-.11-2.37988-.16a80.96226,80.96226,0,0,0-64.94,26.26,82.57424,82.57424,0,0,0-11.32031,15.68,81.446,81.446,0,0,0-9.31982,51.69l11.23974,75.47-2.55957,31.77-3.02,37.44-.16015,2-3.22022,39.92a8.69417,8.69417,0,0,0,5.48,8.8,8.50982,8.50982,0,0,0,3.2002.61005c.16015,0,.31006,0,.47021-.01l159.75-8.59,1.46-.08a8.70416,8.70416,0,0,0,7.83008-11.37Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#ccc'
      />
      <path
        d='M441.1975,291.45155a64.691,64.691,0,0,1-36.97056,11.42551,39.64324,39.64324,0,0,0,15.71262,6.4643,130.44529,130.44529,0,0,1-53.28286.29939,34.48533,34.48533,0,0,1-11.15425-3.831c-3.37787-2.05046-6.20359-5.31552-6.88493-9.20785-1.169-6.67831,4.03481-12.74571,9.44275-16.8348a69.6535,69.6535,0,0,1,58.532-11.69631c6.5384,1.6901,13.08839,4.54566,17.33526,9.79648s5.50486,13.34591,1.45867,18.75289Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#2f2e41'
      />
      <path
        d='M443.62927,593.57759a22.78777,22.78777,0,0,1-31.39874-15.33241l-80.644-7.36881,26.26738-32.87663,72.55386,12.03789a22.91125,22.91125,0,0,1,13.22153,43.54Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#a0616a'
      />
      <path
        d='M398.96825,534.745l-18.23-2.85-18.39014-2.88a14.211,14.211,0,0,1-11.5-17.84l15.96-57.5a32.4688,32.4688,0,0,0-16.04-37.17005,31.98313,31.98313,0,0,0-28.60986-.75,32.07774,32.07774,0,0,0-17.9502,22.29l-20.12988,90.61a43.46415,43.46415,0,0,0,39.51025,52.78l69.62989,4.67A10.18509,10.18509,0,0,0,403.978,577.495c.03028-.13995.04-.28.06006-.42l3.4502-30.84.02978-.28A10.17894,10.17894,0,0,0,398.96825,534.745Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#ccc'
      />
      <path
        d='M707.44512,486.94456l-4.173,2.27685L651.8605,517.27273l-4.173,2.27685a8.71537,8.71537,0,0,0-3.47236,11.8117l38.28506,70.16778a8.71535,8.71535,0,0,0,11.81168,3.47238l.01078-.00588,59.736-32.59327.01078-.00588a8.71535,8.71535,0,0,0,3.47237-11.81168L719.2568,490.417A8.71535,8.71535,0,0,0,707.44512,486.94456Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#e6e6e6'
      />
      <path
        d='M708.64555,489.14449l-4.94258,2.69678L653.8304,519.05283l-4.9425,2.69673a6.2064,6.2064,0,0,0-2.47276,8.41138l38.28506,70.16778a6.20638,6.20638,0,0,0,8.41137,2.47274l.01078-.00588,59.736-32.59326.01085-.00593a6.20637,6.20637,0,0,0,2.4727-8.41132l-38.28507-70.16778A6.20638,6.20638,0,0,0,708.64555,489.14449Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#fff'
      />
      <path
        d='M710.04832,508.955a1.45948,1.45948,0,0,0-1.97021-.58l-1.98975,1.09-20.29,11.07a1.45206,1.45206,0,0,0,1.38965,2.55l22.28028-12.16A1.45963,1.45963,0,0,0,710.04832,508.955Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#e6e6e6'
      />
      <path
        d='M717.63817,510.385a1.43548,1.43548,0,0,0-1.96-.57l-27.53028,15.02a1.44776,1.44776,0,1,0,1.39014,2.54l1.28027-.69995,8.00977-4.37006h.01025l18.21973-9.95A1.43939,1.43939,0,0,0,717.63817,510.385Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#e6e6e6'
      />
      <path
        d='M687.71825,534.325l-8.74024-16.03a.70192.70192,0,0,0-.94971-.27l-4.24023,2.31-2.19971,1.2-11.81006,6.44-2.29,1.25a.70222.70222,0,0,0-.27.95l.56,1.03,8.17969,15a.69309.69309,0,0,0,.93994.27l14.1001-7.69,2.2002-1.2,4.23974-2.31A.69677.69677,0,0,0,687.71825,534.325Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#e6e6e6'
      />
      <path
        d='M721.09813,529.295a1.44744,1.44744,0,0,0-1.96-.58L688.918,545.205a1.44975,1.44975,0,0,0-.73,1.53,1.21141,1.21141,0,0,0,.15039.44,1.47285,1.47285,0,0,0,.29.37l.00977.01a1.462,1.462,0,0,0,1.66992.2l30.22021-16.49A1.44715,1.44715,0,0,0,721.09813,529.295Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#e6e6e6'
      />
      <path
        d='M728.69823,530.725a1.44981,1.44981,0,0,0-1.97022-.57l-35.46972,19.35a1.41479,1.41479,0,0,0-.73,1.51v.01a1.20984,1.20984,0,0,0,.14991.44,1.39909,1.39909,0,0,0,.2998.38,1.42971,1.42971,0,0,0,1.66992.2l35.47022-19.35A1.45655,1.45655,0,0,0,728.69823,530.725Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#e6e6e6'
      />
      <circle cx='436.4441' cy='407.19056' r='6.15041' fill='#e6e6e6' />
      <path
        d='M725.44512,459.94456l-4.173,2.27685L669.8605,490.27273l-4.173,2.27685a8.71537,8.71537,0,0,0-3.47236,11.8117l38.28506,70.16778a8.71535,8.71535,0,0,0,11.81168,3.47238l.01078-.00588,59.736-32.59327.01078-.00588a8.71535,8.71535,0,0,0,3.47237-11.81168L737.2568,463.417A8.71535,8.71535,0,0,0,725.44512,459.94456Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#ccc'
      />
      <path
        d='M726.64555,462.14449l-4.94258,2.69678L671.8304,492.05283l-4.9425,2.69673a6.2064,6.2064,0,0,0-2.47276,8.41138l38.28506,70.16778a6.20638,6.20638,0,0,0,8.41137,2.47274l.01078-.00588,59.736-32.59326.01085-.00593a6.20637,6.20637,0,0,0,2.4727-8.41132l-38.28507-70.16778A6.20638,6.20638,0,0,0,726.64555,462.14449Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#fff'
      />
      <path
        d='M728.04832,481.955a1.45948,1.45948,0,0,0-1.97021-.58l-10.25977,5.6-2.86035,1.56-9.15967,5a1.45206,1.45206,0,0,0,1.38965,2.55l10.8003-5.89,2.25976-1.24,9.22022-5.03A1.45963,1.45963,0,0,0,728.04832,481.955Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#ccc'
      />
      <path
        d='M735.63817,483.385a1.43548,1.43548,0,0,0-1.96-.57l-14.31006,7.81-2.2002,1.2-11.02,6.01a1.44776,1.44776,0,1,0,1.39014,2.54l11.02-6.01,2.2002-1.2,14.2998-7.81A1.43939,1.43939,0,0,0,735.63817,483.385Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#ccc'
      />
      <path
        d='M705.71825,507.325l-6.97022-12.78-1.19971-2.2-.57031-1.05a.70192.70192,0,0,0-.94971-.27l-14.1499,7.72-6.39013,3.48005a.70222.70222,0,0,0-.27.95l.56982,1.04.62012,1.15.58008,1.05v.01l6.96972,12.78a.69309.69309,0,0,0,.93994.27l20.54-11.2A.69677.69677,0,0,0,705.71825,507.325Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#5c50ff'
      />
      <path
        d='M739.09813,502.295a1.44744,1.44744,0,0,0-1.96-.58l-9.03028,4.93-2.1997,1.2-8.72022,4.75-10.27,5.61a1.45214,1.45214,0,0,0,1.39014,2.55l18.99023-10.36,2.19971-1.2,9.03027-4.93A1.44715,1.44715,0,0,0,739.09813,502.295Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#ccc'
      />
      <path
        d='M746.69823,503.725a1.44981,1.44981,0,0,0-1.97022-.57l-14.27,7.79-2.1997,1.2-19,10.36a1.44766,1.44766,0,1,0,1.38964,2.54l18.99024-10.36,2.20019-1.2,14.27979-7.79A1.45655,1.45655,0,0,0,746.69823,503.725Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#ccc'
      />
      <circle cx='454.4441' cy='380.19056' r='6.15041' fill='#ccc' />
      <path
        d='M700.92771,562.68332a19.79115,19.79115,0,0,1,3.06756.54874l69.75009-61.87614-4.5103-22.19787,32.61085-11.18542,11.80356,38.521a15.04079,15.04079,0,0,1-6.12594,16.97964l-88.67413,58.2191a19.73558,19.73558,0,1,1-17.92169-19.009Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#ffb8b8'
      />
      <path
        d='M821.75829,473.515,801.998,435.775a23.47654,23.47654,0,0,0-29.21973-14.54c-.22021.06-.43017.13-.6499.21a23.52681,23.52681,0,0,0-14.46045,29.93l7.93018,41.71a8.44646,8.44646,0,0,0,4.08008,5.74,8.25123,8.25123,0,0,0,3.77978,1.12,8.13863,8.13863,0,0,0,3.23-.45l4.24024-1.48,27.21972-9.48,8.90039-3.1a8.47065,8.47065,0,0,0,4.71-11.92Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#5c50ff'
      />
      <path
        d='M896.35207,619.085h-653a1,1,0,0,1,0-2h653a1,1,0,0,1,0,2Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#3f3d56'
      />
      <path
        d='M355.84813,600.585v.5a17.5147,17.5147,0,0,0,10.43017,16,17.23214,17.23214,0,0,0,7.06983,1.5h161.5v-18Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#3f3d56'
      />
      <path
        d='M647.60789,481.955a32.59279,32.59279,0,0,0-11.41992-13.02,31.97294,31.97294,0,0,0-7.1499-3.54,32.37532,32.37532,0,0,0-10.68994-1.81h-155a32.53759,32.53759,0,0,0-32.5,32.5v90a31.37346,31.37346,0,0,0,.22021,3.78,32.78466,32.78466,0,0,0,1.13965,5.54,31.94021,31.94021,0,0,0,2.06006,5.18,32.46476,32.46476,0,0,0,29.08008,18h155a32.48771,32.48771,0,0,0,32.5-32.5v-90A32.22154,32.22154,0,0,0,647.60789,481.955Z'
        transform='translate(-242.35207 -147.91501)'
        fill='#3f3d56'
      />
      <circle cx='306.99606' cy='393.16998' r='8' fill='#fff' />
    </svg>
  </Box>
)

CoWorkers.defaultProps = {
  color: customTheme.colors.dodger,
}

export default CoWorkers
