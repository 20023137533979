import { api } from 'providers/clientSide/internal'

import { getHttpService } from 'utils/http'

import { DateTimeRevalidationStatus } from 'types/datetime'

export const getServiceDatetime = () =>
  getHttpService<DateTimeRevalidationStatus>({
    fetcher: api.get,
    path: '/datetime',
  })
