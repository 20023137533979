import { Company } from 'models/companies'

import { api } from 'providers/clientSide/nexpy'

import { getHttpService } from 'utils/http'

export const getServiceCompanyRecommendations = ({
  forUserId,
  isMobile,
}: {
  forUserId: string | null
  isMobile: boolean
}) =>
  getHttpService<Company[]>({
    fetcher: api.get,
    path: '/recommendations/companies/public',
    args: {
      params: {
        ...(forUserId
          ? {
              forUserId,
            }
          : {}),

        isMobile,
      },
    },
  })
